<template>
	<b-navbar spaced wrapper-class="container">
		<template slot="brand">
			<b-navbar-item tag="router-link" :to="{ path: '/organizations' }">
				<img src="../assets/moveUP-symbol-green.svg" alt="moveUP" />
			</b-navbar-item>
		</template>
		<template v-if="user && user._id" slot="start">
			<b-navbar-item v-translate tag="router-link" :to="{ path: '/organizations' }">
				Organizations
			</b-navbar-item>
		</template>

		<template slot="end">
			<b-navbar-item v-translate href="https://support.orthopedics.moveup.care/" target="_blank" rel="noopener">
				Support
			</b-navbar-item>
			<b-navbar-dropdown v-if="user && user._id" :label="$gettext('Account')">
				<b-navbar-item v-translate tag="router-link" :to="{ path: '/settings' }">Settings</b-navbar-item>
				<b-navbar-item v-translate tag="router-link" :to="{ path: '/logout' }">Log out</b-navbar-item>
			</b-navbar-dropdown>
		</template>
	</b-navbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState('auth', ['user']),
	},
};
</script>

<style lang="scss" scoped>
.is-refreshing {
	animation-name: turn;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

@keyframes turn {
	0% {
		transform: rotateY(0deg);
	}
	100% {
		transform: rotateY(360deg);
	}
}
</style>
