<template>
	<section>
		<h1 v-translate class="is-size-1">Organizations</h1>
		<div class="columns">
			<div v-if="checkedRows.length !== 0" class="column">
				<!-- buttons that only visible when some rowes are checked -->
				<div class="buttons">
					<b-dropdown :triggers="['hover']" aria-role="list" class="mr-2" disabled>
						<b-button slot="trigger" icon-left="download" icon-right="menu-down">
							<translate>Export</translate>
						</b-button>
						<b-dropdown-item aria-role="listitem">
							<translate>Normal</translate>
						</b-dropdown-item>
						<b-dropdown-item aria-role="listitem">
							<translate>Anonymized</translate>
						</b-dropdown-item>
					</b-dropdown>
					<b-button icon-left="email" disabled>
						<translate>Send email</translate>
					</b-button>
					<b-button type="is-danger is-outlined" icon-left="delete" @click="confirmDelete">
						<translate>Delete</translate>
					</b-button>
					<span class="pb-1">
						<translate
							:translate-n="checkedRows.length"
							:translate-params="{ total: checkedRows.length }"
							translate-plural="%{total} selected"
						>
							%{total} selected</translate
						>
					</span>
				</div>
			</div>
			<div class="column" :class="{ 'is-narrow': checkedRows.length > 0 }">
				<b-input
					v-model="filter.name"
					:placeholder="$gettext('Search')"
					type="search"
					icon="magnify"
					@input="search"
				>
				</b-input>
			</div>
			<div class="column is-narrow">
				<!-- buttons that are always visible -->
				<div class="buttons is-right">
					<b-button icon-left="plus" type="is-primary" tag="router-link" to="/organization">
						<translate>New organization</translate>
					</b-button>
				</div>
			</div>
		</div>
		<b-table
			ref="organizationsTable"
			checkable
			striped
			hoverable
			:data="data"
			:loading="loading"
			paginated
			:current-page.sync="page"
			backend-pagination
			backend-sorting
			backend-filtering
			:total="total"
			:per-page="perPage"
			:checked-rows.sync="checkedRows"
			:aria-next-label="$gettext('Next page')"
			:aria-previous-label="$gettext('Previous page')"
			:aria-page-label="$gettext('Page')"
			:aria-current-label="$gettext('Current page')"
			:default-sort-direction="defaultSortOrder"
			:default-sort="[sortField, sortOrder]"
			:debounce-search="250"
			@sort="onSort"
			@page-change="onPageChange"
			@filters-change="onFilter"
		>
			<b-table-column field="name" :label="$gettext('Name')" sortable>
				<template #default="props">
					<router-link
						:to="`/organization/${props.row._id}/basic-info`"
						tag="span"
						class="is-clickable"
						:class="{ 'is-strikethrough': !props.row.active }"
					>
						<!-- eslint-disable-next-line vue/no-v-html -->
						<span v-html="props.row.name"></span>
					</router-link>
				</template>
			</b-table-column>
			<b-table-column
				v-slot="props"
				:label="$gettext('Cust. Email')"
				field="moveup.communication"
				sortable
				numeric
			>
				<router-link
					:to="`/organization/${props.row._id}/communication`"
					tag="span"
					class="is-clickable is-no-wrap"
				>
					{{ props.row.moveup.communication ? props.row.moveup.communication.length : 0 }}
				</router-link>
			</b-table-column>

			<b-table-column
				v-slot="props"
				:label="$gettext('Surgeries')"
				field="moveup.activityDefinitions"
				sortable
				numeric
			>
				<router-link
					:to="`/organization/${props.row._id}/associations`"
					tag="span"
					class="is-clickable is-no-wrap"
					:class="warningTextIfNone(props.row.moveup.activityDefinitions)"
				>
					{{ props.row.moveup.activityDefinitions ? props.row.moveup.activityDefinitions.length : 0 }}
				</router-link>
			</b-table-column>

			<b-table-column v-slot="props" field="moveup.carePlans" sortable numeric :label="$gettext('Care plans')">
				<router-link
					:to="`/organization/${props.row._id}/associations`"
					tag="span"
					class="is-clickable is-no-wrap"
					:class="warningTextIfNone(props.row.moveup.carePlans)"
				>
					{{ props.row.moveup.carePlans ? props.row.moveup.carePlans.length : 0 }}
				</router-link>
			</b-table-column>

			<b-table-column v-slot="props" field="moveup.surgeons" sortable numeric :label="$gettext('Surgeons')">
				<router-link
					:to="`/organization/${props.row._id}/associations`"
					tag="span"
					class="is-clickable is-no-wrap"
					:class="warningTextIfNone(props.row.moveup.surgeons)"
				>
					{{ props.row.moveup.surgeons ? props.row.moveup.surgeons.length : 0 }}
				</router-link>
			</b-table-column>

			<b-table-column
				v-slot="props"
				:label="$gettext('Physiotherapists')"
				field="moveup.physiotherapists"
				sortable
				numeric
			>
				<router-link
					:to="`/organization/${props.row._id}/associations`"
					tag="span"
					class="is-clickable is-no-wrap"
					:class="warningTextIfNone(props.row.moveup.physiotherapists)"
				>
					{{ props.row.moveup.physiotherapists ? props.row.moveup.physiotherapists.length : 0 }}
				</router-link>
			</b-table-column>

			<b-table-column v-slot="props" field="moveup.studies" sortable numeric :label="$gettext('Studies')">
				<router-link :to="`/organization/${props.row._id}/studies`" tag="span" class="is-clickable is-no-wrap">
					{{ props.row.moveup.studies ? props.row.moveup.studies.length : 0 }}
				</router-link>
			</b-table-column>

			<b-table-column v-slot="props" field="moveup.careTeams" sortable numeric :label="$gettext('Care teams')">
				<router-link
					:to="`/organization/${props.row._id}/careteams`"
					tag="span"
					class="is-clickable is-no-wrap"
					:class="warningTextIfNone(props.row.moveup.careTeams)"
				>
					{{ props.row.moveup.careTeams ? props.row.moveup.careTeams.length : 0 }}
				</router-link>
			</b-table-column>
			<b-table-column v-slot="props" field="isConvention" sortable :label="$gettext('Convention')">
				<router-link
					:to="`/organization/${props.row._id}/associations`"
					tag="span"
					class="is-clickable is-no-wrap"
				>
					<b-icon v-if="isConvention(props)" icon="check" class="has-text-primary" />
				</router-link>
			</b-table-column>
			<template slot="bottom-left">
				<b-select :value="perPage" @input="onPerPageChange">
					<option v-for="value in [20, 50, 100]" :key="value" v-translate="{ value }" :value="value">
						%{value} per page
					</option>
				</b-select>
			</template>
		</b-table>
	</section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { equals, omit } from 'ramda';

export default {
	data() {
		return {
			total: 0,
			loading: false,
			checkedRows: [],
			sortField: 'name',
			sortOrder: 'asc',
			defaultSortOrder: 'asc',
			page: 1,
			filter: {
				name: '',
			},
			searchTerm: '',
			searchTimer: undefined,
			data: [],
		};
	},
	computed: {
		...mapState('auth', ['access']),
		...mapState('common', ['perPage']),
	},
	mounted() {
		// Parse page number from url
		if (this.$route.params.page) this.page = parseInt(this.$route.params.page, 10);

		// Parse sorting query from url
		if (this.$route.query.sortField) this.sortField = this.$route.query.sortField;
		if (this.$route.query.sortOrder) this.sortOrder = this.$route.query.sortOrder;

		// Parse filter from url
		const filter = omit(['sortField', 'sortOrder'], this.$route.query);
		this.$set(this, 'filter', filter);
		Object.keys(filter).forEach(key => {
			this.$set(this.$refs.organizationsTable.filters, key, filter[key]);
		});
		this.loadAsyncData();
	},
	methods: {
		...mapActions('app', ['getOrganizations']),
		...mapActions('common', ['setPerPage']),
		loadAsyncData() {
			Object.keys(this.filter).forEach(key => {
				if (!this.filter[key]) delete this.filter[key];
			});

			const query = { sortField: this.sortField, sortOrder: this.sortOrder, ...(this.filter || {}) };

			if (!equals(query, this.$route.query))
				this.$router.push({
					path: this.$route.path,
					query,
				});

			const params = {
				sortBy: `${this.sortField}|${this.sortOrder}`,
				page: this.page,
				perPage: this.perPage,
				...this.filter,
			};

			this.checkedRows = [];
			this.loading = true;

			this.getOrganizations(params)
				.then(data => {
					this.data = data.results;
					this.total = data.total;
					this.loading = false;
				})
				.catch(err => {
					this.data = [];
					this.total = 0;
					this.loading = false;
					this.errorToast(err);
				});
		},
		confirmDelete() {
			const total = this.checkedRows.length;
			const translated = this.$ngettext(
				'Are you sure you want to delete this organization? This action cannot be undone.',
				'Are you sure you want to delete these %{total} organizations? This action cannot be undone.',
				total,
			);
			const message = this.$gettextInterpolate(translated, { total });
			this.$buefy.dialog.confirm({
				title: this.$gettext('Sure?'),
				message,
				confirmText: this.$gettext('Delete organizations'),
				type: 'is-danger',
				hasIcon: true,
				onConfirm: this.deleteSelected,
				focusOn: 'cancel',
			});
		},
		deleteSelected() {
			const ids = this.checkedRows.map(r => r._id);
			this.deleteOrganizations(ids).then(result => {
				const total = result.deletedCount;
				const translated = this.$ngettext(
					'%{total} organization deleted.',
					'%{total} oragnizations deleted.',
					total,
				);
				const message = this.$gettextInterpolate(translated, { total });
				this.successToast(message);
				this.refresh();
			});
		},
		search(term) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(() => {
				this.loadAsyncData();
			}, 250);
		},
		onPerPageChange(value) {
			this.page = 1;
			// Save perPage in the store, because it's good that this piece of UI info is persisted
			this.setPerPage(value);
			if (this.$route.path !== '/organizations') this.$router.push(`/organizations`);
			this.loadAsyncData();
		},
		onPageChange(page) {
			this.page = page;
			this.$router.push(`/organizations/page/${page}`);
			this.loadAsyncData();
		},
		onSort(field, order) {
			this.sortField = field;
			this.sortOrder = order;
			this.loadAsyncData();
		},
		onFilter(filter) {
			this.filter = filter;
			this.loadAsyncData();
		},
		warningTextIfNone(arr) {
			return !arr || arr.length === 0 ? 'has-text-warning' : '';
		},
		isConvention(props) {
			return props?.row?.moveup?.studies?.includes('Convention');
		},
	},
};
</script>

<style lang="scss">
.is-strikethrough {
	text-decoration: line-through;
	opacity: 0.5;
}
</style>
