import { api } from '@moveup/app-core';
require('json.date-extensions');

const axiosJSONDateParseParams = {
	transformResponse: data => JSON.parseWithDate(data),
};

const initialState = {
	allStudies: [
		'Evolution',
		'Evolution 2',
		'Sphere',
		'Geko',
		'Discharge',
		'Gladiator',
		'Convention',
		'Rosa',
		'Pixee',
		'Dr. Vles',
		'Navio',
		'Pre-assessment',
		'Microport',
		'Zimmer Biomet',
		'PROM',
		'VLAIO',
		'Rosa Persona MC',
		'Skywalker',
	],
	currentlyRefreshing: [],
	lastRefresh: undefined,
	languages: [],
	platformFlavors: [],
};

const store = {
	state: initialState,
	mutations: {
		setLastRefreshTime(state, value) {
			state.lastRefresh = value;
		},
		addToCurrentlyRefreshing(state, value) {
			state.currentlyRefreshing.push(value);
		},
		removeFromCurrentlyRefreshing(state, value) {
			state.currentlyRefreshing = state.currentlyRefreshing.filter(v => v !== value);
		},
		removeAllFromCurrentlyRefreshing(state) {
			state.currentlyRefreshing = [];
		},
		refreshLanguages(state, languages) {
			state.languages = languages;
		},
		refreshPlatformFlavors(state, platformFlavors) {
			state.platformFlavors = platformFlavors;
		}
	},
	getters: {
		languages: state => state.languages,
	},
	actions: {
		// APP-SPECIFIC CALLS TO THE GATEWAY
		async handleRefreshCall({ commit }, { path, params, caller }) {
			console.log(caller);
			commit('addToCurrentlyRefreshing', caller);
			try {
				const result = await api.get(path, params, axiosJSONDateParseParams);
				if (result.results) commit(caller, result.results.map(Object.freeze));
				console.log(`${caller} result:`, result.total);
				return result;
			} catch (err) { console.error(err); } finally {
				commit('removeFromCurrentlyRefreshing', caller);
			}
		},

		refreshLanguages: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshLanguages',
			path: 'languages',
			params,
		}),

		refreshPlatformFlavors: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshPlatformFlavors',
			path: 'platformflavors',
			params,
		}),

		deleteOrganizations(_, params) {
			return api.del('organizations', params);
		},

		async createOrganization({ commit }, params) {
			return await api.post('organizations', { resourceType: 'Organization', ...params });
		},

		async updateOrganization({ commit }, { _id: id, ...params }) {
			return await api.patch(`organizations/${id}`, { resourceType: 'Organization', ...params });
		},

		getOrganization(_, id) {
			return api.get(`organizations/${id}`, null, axiosJSONDateParseParams);
		},

		getCareTeam(_, params) {
			if (typeof params === 'object') return api.get(`careteams`, params, axiosJSONDateParseParams);
			return api.get(`careteams/${params}`, null, axiosJSONDateParseParams);
		},

		getCareTeamAffiliates(_, organizationId) {
			return api.get(`careteams/affiliates/${organizationId}`, null, axiosJSONDateParseParams);
		},

		updateOrganizationCareTeam(_, { organizationId, careTeamId, params }) {
			return api.patch(`organizations/${organizationId}/careteams/${careTeamId}`, params);
		},

		updateCareTeam(_, careTeam) {
			if (!careTeam?._id) return;
			return api.patch(`careteams/${careTeam._id}`, careTeam);
		},

		createCareTeam(_, careTeam) {
			return api.post(`careteams`, careTeam);
		},

		getOrganizationAffiliations(_, organizationId) {
			return api.get(`organizations/${organizationId}/affiliates`, null, axiosJSONDateParseParams);
		},

		updateOrganizationAffiliations(_, { _id, affiliates }) {
			return api.patch(`organizations/${_id}/affiliates`, affiliates);
		},

		createOrganizationAffiliations(_, { _id, affiliates }) {
			return api.post(`organizations/${_id}/affiliates`, affiliates);
		},

		async refreshAll({ commit, dispatch, state }) {
			console.log('state.currentlyRefreshing:', state.currentlyRefreshing);
			commit('removeAllFromCurrentlyRefreshing');
			if (state.currentlyRefreshing.length === 0) {
			console.log('🔄 refreshAll 🔄');
				const promises = [
					dispatch('refreshLanguages'),
					dispatch('refreshPlatformFlavors'),
				];
				commit('setLastRefreshTime', Date.now());
				return Promise.all(promises);
			}
		},

		getOrganizations(_, params) {
			return api.get('organizations', params);
		},
		getEmailTemplates(_, params) {
			return api.get('mails', params);
		},
		getMessageTemplates(_, params) {
			return api.get('messages', params);
		},
		getCarePlans(_, params) {
			return api.get('careplans', params);
		},
		getActivityDefinitions(_, params) {
			return api.get('activitydefinitions', params);
		},
		getSurgeons(_, params) {
			return api.get('practitioners', Object.assign({ type: 'SURGEON' }, params || {}));
		},
		getPhysicalTherapists(_, params) {
			return api.get('practitioners', Object.assign({ type: 'HCP' }, params || {}));
		},
		getAdministrativePersonnel(_, params) {
			return api.get('practitioners', Object.assign({ type: 'ADMIN' }, params || {}));
		},
		getAllOrganizations(_, params) {
			return api.get('allorganizations', params);
		},
		getPlatformFlavors(_, params) {
			return api.get('platformflavors', params);
		},
		getAllConsents(_, params) {
			return api.get('consents', params);
		}
	},
};

export default store;
